var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Company Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_name',
            { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n            'company_name',\n            { rules: [{ required: true, message: 'Field is required!' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"KRA PIN"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'kra_pin',
            { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n            'kra_pin',\n            { rules: [{ required: true, message: 'Field is required!' }] },\n          ]"}],attrs:{"placholder":"KRA PIN"}})],1),_c('a-form-item',{attrs:{"label":"Company Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_email',
            { rules: [{ required: true, message: 'Field is required!' }] }, {
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input E-mail!',
            } ],
        } ]),expression:"[\n            'company_email',\n            { rules: [{ required: true, message: 'Field is required!' }] }, {\n          rules: [\n            {\n              type: 'email',\n              message: 'The input is not valid E-mail!',\n            },\n            {\n              required: true,\n              message: 'Please input E-mail!',\n            },\n          ],\n        },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_phone',
            {
              rules: [
                { required: true, message: 'Please input phone number!' } ],
            } ]),expression:"[\n            'company_phone',\n            {\n              rules: [\n                { required: true, message: 'Please input phone number!' },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['prefix', { initialValue: '86' }]),expression:"['prefix', { initialValue: '86' }]"}],staticStyle:{"width":"70px"},attrs:{"slot":"addonBefore"},slot:"addonBefore"},[_c('a-select-option',{attrs:{"value":"86"}},[_vm._v(" +254 ")]),_c('a-select-option',{attrs:{"value":"87"}},[_vm._v(" +1 ")])],1)],1)],1),_c('a-form-item',{attrs:{"label":"Company Address"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_address',
            { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n            'company_address',\n            { rules: [{ required: true, message: 'Field is required!' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"Company Logo"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'logo',
            { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n            'logo',\n            { rules: [{ required: true, message: 'Field is required!' }] },\n          ]"}],attrs:{"name":"file","multiple":false,"list-type":"picture","transform-file":_vm.transformFile,"file-list":_vm.fileList,"remove":_vm.handleRemove,"before-upload":_vm.beforeUpload}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Click to Upload logo")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }